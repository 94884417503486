import { AsyncButton, Button } from '@components/button';
import { Form, FormTextInput } from '@components/form';
import { useDuplicateGraphMutation } from '@generated/UseGraphqlHooks';
import { Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { navigate } from 'gatsby';
import * as yup from 'yup';

interface DuplicateGraphProps {
  description?: string;
  graphId?: string;
  name?: string;
  workspaceId?: string;
  onClose: () => void;
  refetch?: (newGraphId?: string) => void;
}

const validationSchema = yup.object({
  newName: yup
    .string()
    .trim()
    .max(128, 'Must be less than 128 characters.')
    .required('Name is required'),
});

export const GraphsListViewPreviewMenuDuplicateConfiguration = ({
  description,
  graphId,
  name,
  workspaceId,
  onClose,
  refetch,
}: DuplicateGraphProps) => {
  const { useAsyncNotification } = useNotifications();
  const [duplicateGraph] = useDuplicateGraphMutation();

  const handleDuplicationSubmit = useAsyncNotification(
    'Successfully copied graph.',
    async ({ newName, newDescription }: { newName: string; newDescription: string }) => {
      const {
        data: { duplicateGraph: duplicatedGraphId },
      } = await duplicateGraph({
        variables: { workspaceId, graphId, name: newName, description: newDescription },
      });
      if (refetch) {
        refetch(duplicatedGraphId);
      } else {
        await navigate(`/workspaces/${workspaceId}/graphs/${duplicatedGraphId}`);
      }
      onClose();
    },
  );

  return (
    <Form
      validationSchema={validationSchema}
      initialValues={{ newName: `${name} (Copy)`, newDescription: description }}
      onSubmit={handleDuplicationSubmit}
    >
      {({ handleSubmit }) => (
        <Stack>
          <FormTextInput name="newName" label="Name" />
          <FormTextInput name="newDescription" label="Description" multiline rows={3} />
          <Stack gap={4}>
            <AsyncButton
              fullWidth
              data-cy="Graphs-List-View-Preview-Menu-Duplicate-Configuration-Save-Button"
              onClick={handleSubmit}
            >
              Save
            </AsyncButton>
            <Button
              fullWidth
              variant="secondary"
              data-cy="Graphs-List-View-Preview-Menu-Duplicate-Configuration-Cancel-Button"
              onClick={onClose}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      )}
    </Form>
  );
};
