import { Form } from '@components/form';
import { Graph, useCreateDatasetMutation } from '@generated/UseGraphqlHooks';
import { Stack, useTheme } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { useNotifications } from '@notifications/Notifications';
import {
  JobsConfigurationsPreviewExpandedViewForm,
  JobsConfigurationsPreviewExpandedViewInfo,
} from '@subsets/workspaces';
import { useState } from 'react';
import * as yup from 'yup';

const JobPriorities: Record<string, number> = {
  Low: 1,
  Medium: 2,
  High: 3,
};

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .required('Name is required')
    .max(128, 'Must be less than 128 characters'),
  seed: yup
    .number()
    .required('Seed number is required')
    .min(0, 'Seed must be more then or equal to 0')
    .max(2147483647, 'Seed must be less than 2147483648'),
  numberOfRuns: yup
    .number()
    .required('Required')
    .min(1, 'Number of runs must be more than or equal to 1')
    .max(2147483647, 'Number of runs must be less than 2147483648'),
});

type JobsConfigurationsPreviewExpandedViewProps = Pick<
  Graph,
  'description' | 'graphId' | 'name' | 'workspaceId' | 'channelId' | 'channel'
> & {
  checked: boolean;
  refetchJobs: (additional: number) => void;
};

interface RunJobSubmissionType {
  name: string;
  description: string;
  priority: string;
  numberOfRuns: string;
  seed: string;
}

export const JobsConfigurationsPreviewExpandedView = ({
  description,
  checked,
  channel,
  channelId,
  graphId,
  name,
  workspaceId,
  refetchJobs,
}: JobsConfigurationsPreviewExpandedViewProps) => {
  const { palette } = useTheme();
  const { useAsyncNotification } = useNotifications();
  const [createJob] = useCreateDatasetMutation();
  const [defaultSeed, setDefaultSeed] = useState(Number(Math.floor(Math.random() * 1000000000)));

  const handleRunJobSubmission = useAsyncNotification(
    'Successfully started new job.',
    async (
      {
        name: jobName,
        description: jobDescription,
        priority,
        numberOfRuns,
        seed,
      }: RunJobSubmissionType,
      { resetForm }: { resetForm: () => void },
    ) => {
      await createJob({
        variables: {
          graphId,
          workspaceId,
          name: jobName,
          description: jobDescription || jobName,
          runs: Number(numberOfRuns),
          priority: JobPriorities[priority],
          seed: Number(seed),
        },
      });
      void refetchJobs(1);
      setDefaultSeed(Number(Math.floor(Math.random() * 1000000000)));
      resetForm();
    },
  );

  return (
    <Collapse in={checked} mountOnEnter unmountOnExit>
      <Form
        onSubmit={handleRunJobSubmission}
        initialValues={{
          name,
          seed: defaultSeed,
          numberOfRuns: 1,
          description: description || '',
          priority: 'Low',
        }}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ isValid, isSubmitting }) => (
          <Stack sx={{ backgroundColor: palette.background.paper, p: 3, pt: 0 }}>
            <JobsConfigurationsPreviewExpandedViewInfo
              channel={channel}
              channelId={channelId}
              graphId={graphId}
              workspaceId={workspaceId}
            />
            <JobsConfigurationsPreviewExpandedViewForm
              isSubmitting={isSubmitting}
              isValid={isValid}
            />
          </Stack>
        )}
      </Form>
    </Collapse>
  );
};
