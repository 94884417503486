import seedRandom from 'seedrandom';
import { ImageAnnotations } from './DatasetsDetailsImagesSliderTypes';

interface DatasetsDetailsImagesSlider2DBoxesOverlayProps {
  data: ImageAnnotations[];
  strokeWidth: number;
}

export const DatasetsDetailsImagesSlider2DBoxesOverlay = ({
  data,
  strokeWidth,
}: DatasetsDetailsImagesSlider2DBoxesOverlayProps) => {
  const seededRandom = seedRandom(JSON.stringify(data));
  return (
    <>
      {data?.map(({ id, bbox }, index) => {
        if (!bbox) {
          return null;
        }
        const stroke = `rgb(${seededRandom.quick() * 255}, ${seededRandom.quick() * 255}, ${
          seededRandom.quick() * 255
        })`;
        return (
          <rect
            key={id || index}
            width={bbox[2]}
            height={bbox[3]}
            x={bbox[0]}
            y={bbox[1]}
            style={{
              fill: 'none',
              strokeWidth,
              stroke,
            }}
          />
        );
      })}
      Sorry, your browser does not support inline SVG.
    </>
  );
};
