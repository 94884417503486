import { ImageAnnotations } from './DatasetsDetailsImagesSliderTypes';

interface DatasetsDetailsImagesSliderMaskOverlayProps {
  data: ImageAnnotations[];
}

export const DatasetsDetailsImagesSliderMaskOverlay = ({
  data,
}: DatasetsDetailsImagesSliderMaskOverlayProps) => {
  return (
    <>
      {data?.map(({ segmentation }, idx) => {
        if (!segmentation) {
          return null;
        }
        const fillValue = (255 / (data.length + 2)) * (idx + 2);
        return segmentation.map((seg, index) => {
          return (
            <polygon
              // eslint-disable-next-line react/no-array-index-key
              key={`${idx}-${index}`}
              points={seg
                .map((e, i) => {
                  if (i % 2 === 0 && i < seg.length - 1) {
                    return `${e},${seg[i + 1]}`;
                  }
                  return '';
                })
                .join(' ')}
              fill={`rgb(${fillValue},${fillValue},${fillValue})`}
            />
          );
        });
      })}
      Sorry, your browser does not support inline SVG.
    </>
  );
};
